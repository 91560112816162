const loyalty = [
  "Boots",
  "Costco",
  "H&B",
  //"Iceland",
  "Ikea",
  "Tesco",
  "John Lewis",
  //"Lidl",
  // "M&S",
  // "TKMAXX",
  "Waitrose",
];
const energySuppliers = [
  "British Gas",
  "Budget Energy",
  //"Bulb Energy",
  "Click Energy",
  "Co-Operative Energy",
  "E.ON",
  "E.ON Next",
  "EBICO",
  "Ecotricity",
  "EDF Energy",
  "Electric Ireland",
  "ESB Energy",
  "Future Energy",
  "Good Energy",
  "Green Energy UK",
  "Green.Energy",
  "M&S Energy",
  "Nabuh",
  "National Gas",
  "Octopus Energy",
  "Out Fox the Market",
  "Power NI",
  "Qwest",
  "Sainsbury's Energy",
  "Scottish Power",
  "Shell Energy",
  "So Energy",
  "SSE",
  "Utilita",
  "Utility Warehouse",
];

const waterSuppliers = [
  "Anglian Water",
  "Northern Ireland Water",
  "Dwr Cymru Welsh Water",
  "Northumbrian Water",
  "Severn Trent",
  "South West Water",
  "Southern Water",
  "Thames Water",
  "United Utilities",
  "Wessex Water",
  "Yorkshire Water",
  "Affinity Water",
  "Bournemouth Water",
  "Bristol Water",
  "Cambridge Water",
  "Essex & Suffolk Water",
  "Portsmouth Water",
  "South East Water",
  "South Staffordshire Water",
  "SES Water",
];
const dentists = [""];

const mobiles = [
  "Asda Mobile",
  "BT Mobile",
  "CMLink",
  "Ecotalk",
  "EE",
  "Extreme Connect",
  "giffgaff",
  "iD Mobile",
  "Lebara Mobile",
  "Lycamobile",
  "Now",
  // "O2",
  "Plusnet Mobile",
  "Sky Mobile",
  "SMARTY",
  "Superdrug Mobile",
  "Talkmobile",
  "TalkTalk Mobile",
  "Tesco Mobile",
  "The Phone Co-op",
  "Three",
  "Truphone",
  "Utility Warehouse-Telecom Plus",
  "Virgin Mobile",
  "VOXI",
  "Vodafone",
];
const charities = [
  "ActionAid",
  "Alzheimer's Society",
  "Amnesty International",
  "Barnardos",
  "Book Trust",
  "British Heart Foundation",
  "Campaign for Nuclear Disarmament",
  "Cancer Research UK",
  "Diabetes UK",
  "Dignity in Dying",
  "Dogs Trust",
  "Duke of Edinburgh Awards",
  "Friends of the Earth",
  "Great Ormond Street Hospital",
  "Kidney Research UK",
  "London's Air Ambulance",
  "Marie Curie",
  "Medecins Sans Frontieres",
  "Mind",
  "Movember",
  "National Trust",
  "National Trust for Scotland",
  "Oxfam",
  "PETA",
  "Prostate Cancer UK",
  "Samaritans",
  "Shelter",
  "Sightsavers",
  "The Blue Cross",
  "The Children's Society",
  "The Fire Fighters Charity",
  "UNICEF",
  "WaterAid",
  "WWF",
  "Action on Smoking and Health",
  "Arthiritis Research UK",
  "Bowel Cancer UK",
  "Breast Cancer Care",
  "Breast Cancer Now",
  "British Red Cross",
  "CAFOD",
  "Cats Protection",
  "Centrepoint",
  "Children in Need",
  "Children with Cancer",
  "Christian Aid",
  "CLIC Sargent",
  "GambleAware",
  "Greenpeace",
  "Guide Dogs",
  "Hearing Dogs",
  "Human Appeal",
  "LGBT Foundation",
  "Macmillan Cancer Support",
  "Mencap",
  "Midlands Air Ambulance",
  "MS Society",
  "NSPCC",
  "Parkinson's UK",
  "RSPCA",
  "Salvation Army",
  "Save the Children",
  "Scope",
  "St Lukes",
  "Teenage Cancer Trust",
  "The Aplastic Anaemia Trust",
  "The Fairtrade Foundation",
  "The National Autistic Society",
  "The Scout Association",
  "Wood Green, The Animals Charity",
  "Woodland Trust",
].sort();

const gyms = [
  "Fitness first",
  "The Gym",
  "Virgin Active",
  "Puregym",
  "Xercise4less",
  "Nuffield Health",
].sort();

const opticians = [
  "Vision Express",
  "Specsavers",
  "Scrivens",
  "Optical Express",
  "Asda Opticians",
].sort();

const nationalities = [
  "British",
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape verdean",
  "Central african",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New zealander",
  "Ni-vanuatu",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North korean",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua new guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint lucian",
  "Salvadoran",
  "Samoan",
  "San marinese",
  "Sao tomean",
  "Saudi",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon islander",
  "Somali",
  "South african",
  "South korean",
  "Spanish",
  "Sri lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];

const banks = [
  "American Express",
  "Bank of Scotland",
  //"Barclays Bank",
  //"Barclaycard",
  "Citibank",
  //"Clydesdale",
  //"Co-Operative Bank",
  // "First Direct",
  "Halifax",
  //"HSBC",
  //"Investec",
  "Lloyds",
  //"Metro Bank",
  "MBNA",
  "Monzo",
  //"M&S Bank",
  //"Nationwide",
  //"Natwest",
  //"RBS",
  "Revolut",
  //"Sainsbury’s Bank",
  //"Santander",
  //"Starling",
  //"Tesco Bank",
  //"TSB",
  //"Virgin Money",
  //"Yorkshire Bank",
];
const gps = [""];
const pensions = [
  "Aviva",
  "Barclays pension fund",
  "BP Pension",
  "BT Pension Scheme",
  // "Canada Life",
  "Equitable Life Pension",
  "Greater Manchester Pension Fund (GMPF)",
  "HBOS",
  "Interactive Investor",
  "Legal & General",
  // "Lloyds Pension",
  "National Grid UK Pension Scheme",
  "Nest",
  "NHS Pensions",
  "Pension Bee",
  "Railway Pension Scheme",
  // "Royal Bank of Scottland (RBS) Pension fund",
  "RPMI Railpen",
  //  "Scottish Widows",
  // "Standard Life",
  "Strathclyde pension fund (SPFO)",
  "Teachers' Pensions",
  "True Potential",
  // "Universities Superannuation Scheme",
  "Your Tomorrow",
];

const titles = [
  "Mr",
  "Ms",
  "Mrs",
  "Miss",
  "Dr",
  "Professor",
  "Lady",
  "Dame",
  "Sir",
  "Judge",
  "Lord",
];

const countries = [
  "United Kingdom",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Caribbean Netherlands",
  "Bosnia & Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo - Brazzaville",
  "Congo - Kinshasa",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Islas Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard & McDonald Islands",
  "Vatican City",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "North Korea",
  "South Korea",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "North Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "St. Barthélemy",
  "St. Helena",
  "St. Kitts & Nevis",
  "St. Lucia",
  "St. Martin",
  "St. Pierre & Miquelon",
  "St. Vincent & Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé & Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia & South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard & Jan Mayen",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States",
  "U.S. Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "British Virgin Islands",
  "U.S. Virgin Islands",
  "Wallis & Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
export {
  loyalty,
  energySuppliers,
  waterSuppliers,
  charities,
  gps,
  pensions,
  gyms,
  opticians,
  mobiles,
  dentists,
  nationalities,
  banks,
  titles,
  countries,
};
